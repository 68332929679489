/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Lato;
    --text-color-light: #7D7D8F;
    --text-color-dark: #222222;
    user-select: none;
    line-height: unset;
}

.bold {
    font-weight: bold;
}
button {
    outline: none !important;
}

// Scrollbar styling
    ::-webkit-scrollbar {
        width: 17px;
    }
    ::-webkit-scrollbar-track {
        background: #EDEDF0;
        border: 5px solid transparent;
        background-clip: padding-box;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #C4C4CE;
        border: 5px solid transparent;
        background-clip: padding-box;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(158, 158, 165);
        border: 5px solid transparent;
        background-clip: padding-box;
        border-radius: 10px;
        // TODO: transition the hover changes
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    // For some reason these style rules for .cdk-drag cannot be set withing the component's css and only works when setting it globally.
    app-category-card, app-route-card {
        .cdk-drag {
            display: none !important; // we don't want a dragging stop adding to the route item's height.
        }
    }

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(assets/fonts/lato/LatoLatin-Regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: local('Lato Medium'), local('Lato-Medium'), url(assets/fonts/lato/LatoLatin-Medium.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src: local('Lato Semi Bold'), local('Lato-Semi-Bold'), url(assets/fonts/lato/LatoLatin-Semibold.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(assets/fonts/lato/LatoLatin-Bold.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Routing modal
.cdk-overlay-backdrop.modal-backdrop.cdk-overlay-backdrop-showing {
    background: #2F3035;
    opacity: .9;
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s, opacity 1s cubic-bezier(0, 0, 0.2, 1);
    }
  
    &:active:after {
      transform: scale(0,0);
      opacity: .2;
      transition: 0s;
    }
  }

.notify-error{
    background-color:#f44336 !important;
    color: white !important;
}

.notify-success{
    background-color:#4caf50 !important;
}

.notify-success .mat-button-wrapper, .notify-error .mat-button-wrapper{
    color: white !important;
}

.btn-loading {
    padding: 5.5px 0 !important;
}
.unassigned{
    text-align: center;
    width: 82%;
}
.cdk-global-overlay-wrapper{
    overflow: auto;
}
.mat-dialog-container{
    display: inline !important;
    margin: 20px;
}